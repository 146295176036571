const regionalMinimumWages = [
  { prefectures__c: "北海道", minimumwage__c: 920.0, id: null },
  { prefectures__c: "青森県", minimumwage__c: 853.0, id: null },
  { prefectures__c: "岩手県", minimumwage__c: 854.0, id: null },
  { prefectures__c: "宮城県", minimumwage__c: 883.0, id: null },
  { prefectures__c: "秋田県", minimumwage__c: 853.0, id: null },
  { prefectures__c: "山形県", minimumwage__c: 854.0, id: null },
  { prefectures__c: "福島県", minimumwage__c: 858.0, id: null },
  { prefectures__c: "茨城県", minimumwage__c: 911.0, id: null },
  { prefectures__c: "栃木県", minimumwage__c: 913.0, id: null },
  { prefectures__c: "群馬県", minimumwage__c: 895.0, id: null },
  { prefectures__c: "埼玉県", minimumwage__c: 987.0, id: null },
  { prefectures__c: "千葉県", minimumwage__c: 984.0, id: null },
  { prefectures__c: "東京都", minimumwage__c: 1072.0, id: null },
  { prefectures__c: "神奈川県", minimumwage__c: 1071.0, id: null },
  { prefectures__c: "新潟県", minimumwage__c: 890.0, id: null },
  { prefectures__c: "富山県", minimumwage__c: 908.0, id: null },
  { prefectures__c: "石川県", minimumwage__c: 891.0, id: null },
  { prefectures__c: "福井県", minimumwage__c: 888.0, id: null },
  { prefectures__c: "山梨県", minimumwage__c: 898.0, id: null },
  { prefectures__c: "長野県", minimumwage__c: 908.0, id: null },
  { prefectures__c: "岐阜県", minimumwage__c: 910.0, id: null },
  { prefectures__c: "静岡県", minimumwage__c: 944.0, id: null },
  { prefectures__c: "愛知県", minimumwage__c: 986.0, id: null },
  { prefectures__c: "三重県", minimumwage__c: 933.0, id: null },
  { prefectures__c: "滋賀県", minimumwage__c: 927.0, id: null },
  { prefectures__c: "京都府", minimumwage__c: 968.0, id: null },
  { prefectures__c: "大阪府", minimumwage__c: 1023.0, id: null },
  { prefectures__c: "兵庫県", minimumwage__c: 960.0, id: null },
  { prefectures__c: "奈良県", minimumwage__c: 896.0, id: null },
  { prefectures__c: "和歌山県", minimumwage__c: 889.0, id: null },
  { prefectures__c: "鳥取県", minimumwage__c: 854.0, id: null },
  { prefectures__c: "島根県", minimumwage__c: 857.0, id: null },
  { prefectures__c: "岡山県", minimumwage__c: 892.0, id: null },
  { prefectures__c: "広島県", minimumwage__c: 930.0, id: null },
  { prefectures__c: "山口県", minimumwage__c: 888.0, id: null },
  { prefectures__c: "徳島県", minimumwage__c: 855.0, id: null },
  { prefectures__c: "香川県", minimumwage__c: 878.0, id: null },
  { prefectures__c: "愛媛県", minimumwage__c: 853.0, id: null },
  { prefectures__c: "高知県", minimumwage__c: 853.0, id: null },
  { prefectures__c: "福岡県", minimumwage__c: 900.0, id: null },
  { prefectures__c: "佐賀県", minimumwage__c: 853.0, id: null },
  { prefectures__c: "長崎県", minimumwage__c: 853.0, id: null },
  { prefectures__c: "熊本県", minimumwage__c: 853.0, id: null },
  { prefectures__c: "大分県", minimumwage__c: 854.0, id: null },
  { prefectures__c: "宮崎県", minimumwage__c: 853.0, id: null },
  { prefectures__c: "鹿児島県", minimumwage__c: 853.0, id: null },
  { prefectures__c: "沖縄県", minimumwage__c: 853.0, id: null },
];

/**
 * 都道府県名から地域別最低賃金を取得する
 *
 * @function
 * @param {string} prefecture
 * @returns {number|undefined}
 */
const findRegionalMinimumWage = (prefecture) => {
  return regionalMinimumWages.find(
    (wage) => wage.prefectures__c === prefecture
  )?.minimumwage__c;
};

window.findRegionalMinimumWage = findRegionalMinimumWage;
