/**
 * 住所の取得が成功すると呼び出されるコールバック関数
 *
 * @callback fetchAddressCallback
 * @param {{prefecture: string, city: string, town: string}} address
 * @returns {void}
 */

/**
 * 郵便番号から住所を取得する
 *
 * @function
 * @param {fetchAddressCallback} callback
 * @param {string} zipcode
 * @returns {void}
 */
const fetchAddress = (callback, zipcode) => {
  const api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=';

  // 想定される特殊文字を削除して数値のみの文字列に変換
  const zipcodeNumericOnly = zipcode.replace(/[-_]/g, '');

  if (zipcodeNumericOnly.length !== 7) {
    return;
  }

  fetch(api + zipcodeNumericOnly)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 400) {
        console.error('error: ' + data.message);
      } else if (data.results === null) {
        console.error('error: not found adress');
      } else {
        callback({
          prefecture: data.results[0].address1,
          city: data.results[0].address2,
          town: data.results[0].address3
        });
      }
    })
    .catch((ex) => {
      console.error(ex);
    });
};

window.fetchAddress = fetchAddress;
