// ラベルに必須表示をする
$('label.required').each(function (index, element) {
  let $element = $(element);
  const html = $element.html();
  $element.html(html + '<span class="badge badge-danger ml-1">必須</span>');
});
// ラベルにCC入力表示をする
$('label.cc-input').each(function (index, element) {
  let $element = $(element);
  const html = $element.html();
  $element.html(html + '<span class="badge badge-success ml-1">CC項目</span>');
});

// for popover
$('[data-toggle="tooltip"]').popover();

$(() => {
  // for bsCustomFileInput
  bsCustomFileInput.init();
});
